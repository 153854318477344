"use client";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { ReactNode, useState, useRef } from "react";

type Props = {
  children: ReactNode;
  additionalContent?: ReactNode;
};

function PricingCarousel({ children, additionalContent }: Props) {
  const maxChildren = 5;
  const limitedChildren = React.Children.toArray(children).slice(
    0,
    maxChildren
  );

  const [positionIndexes, setPositionIndexes] = useState(
    Array.from({ length: maxChildren }, (_, i) => i)
  );

  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleNext = () => {
    setPositionIndexes((prevIndexes) =>
      prevIndexes.map((index) => (index + 1) % maxChildren)
    );
  };

  const handleBack = () => {
    setPositionIndexes((prevIndexes) =>
      prevIndexes.map((index) => (index + maxChildren - 1) % maxChildren)
    );
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      handleNext();
    }

    if (touchStartX.current - touchEndX.current < -50) {
      handleBack();
    }
  };

  const positions = ["center", "left1", "left", "right", "right1"];

  const imageVariants = {
    center: { x: "0%", scale: 1, zIndex: 5 },
    left1: { x: "-90%", scale: 0.7, zIndex: 3 },
    left: { x: "-150%", scale: 0.5, zIndex: 2 },
    right: { x: "150%", scale: 0.5, zIndex: 1 },
    right1: { x: "90%", scale: 0.7, zIndex: 3 },
  };

  return (
    <>
      <div
        className="relative mx-auto flex h-[500px] flex-col items-center justify-center"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {React.Children.map(limitedChildren, (child, index) => (
          <motion.div
            key={index}
            className="rounded-[12px]"
            initial="center"
            animate={positions[positionIndexes[index]]}
            variants={imageVariants}
            transition={{ duration: 0.5 }}
            style={{ position: "absolute" }}
          >
            {child}
          </motion.div>
        ))}
      </div>
      {additionalContent && (
        <div className="my-4 text-center">
          {additionalContent}
        </div>
      )}
      <div className="mt-8 flex flex-row justify-center gap-3">
        <button
          aria-label="précedent"
          className=" rounded-xl bg-foreground text-inverse-foreground"
          onClick={handleBack}
        >
          <ChevronLeft width={50} height={50} />
        </button>
        <button
          aria-label="Suivant"
          className="rounded-xl bg-foreground text-inverse-foreground"
          onClick={handleNext}
        >
          <ChevronRight width={50} height={50} />
        </button>
      </div>
    </>
  );
}

export default PricingCarousel;
